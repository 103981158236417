import accounting from 'accounting'

const getDecimalSeparator = () => {
  const numberWithDecimalSeparator = 1.1

  const locale = navigator.language || 'en-GB'

  const decimalSeparator = Intl.NumberFormat(locale)
    .formatToParts(numberWithDecimalSeparator)
    .find(part => part.type === 'decimal')?.value!

  return decimalSeparator
}

export const unformatNumber = (input: string) => {
  const decimalSeparator = getDecimalSeparator()
  return accounting.unformat(input, decimalSeparator).toString()
}
