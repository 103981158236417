import { useCallback, useEffect, useRef, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import enterpriseClient from '../clients/EnterpriseClient'
import { FinancialPromotion } from '../types'
import useAsyncEffect from '../util/useAsyncEffect'
import { ZumoLogo } from '../assets/icons'
import RiskWarningForm from '../screens/RiskWarningForm'
import CategorySelectionForm from '../screens/CategorySelectionForm'
import {
  CategorisationCategoryType,
  SessionStorageKeys,
  RiskWarningStatus,
} from '../enums'
import CancelFlow from '../screens/CancelFlow'
import CategorisationForm from '../screens/CategorisationForm'
import AppropriatenessAssessmentForm from '../screens/AppropriatenessAssessmentForm'
import { SearchParams } from '../enums/SearchParams'
import { handleError } from '../util/error'
import ErrorScreen from '../screens/ErrorScreen'
import ZContainer from '../components/ZContainer'
import PreAppropriatenessAssessment from '../screens/PreAppropriatenessAssessment'
import CloseWebApp from '../screens/CloseWebApp'
import AppropriatenessAssessmentCompleted from '../screens/AppropriatenessAssessmentCompleted'
import { ConfigUtils } from '../util/configUtils'
import config from '../config'

const Home = () => {
  const [searchParams] = useSearchParams()

  const [financialPromotion, setFinancialPromotion] =
    useState<FinancialPromotion>()
  const [error, setError] = useState<boolean>(false)

  const [declinedRiskWarning, setDeclinedRiskWarning] = useState<boolean>()
  const [selectedCategory, setSelectedCategory] =
    useState<CategorisationCategoryType>()
  const [proceedToAssessment, setProceedToAssessment] = useState<boolean>()
  const [flowEnded, setFlowEnded] = useState<boolean>()
  const [submittedAssessment, setSubmittedAssessment] = useState<boolean>()
  const [cancelCategorisation, setCancelCategorisation] = useState<boolean>()
  const [submittingCategorisation, setSubmittingCategorisation] =
    useState<boolean>(false)
  const [submittingRiskWarning, setSubmittingRiskWarning] =
    useState<boolean>(false)
  const [submittingAssessment, setSubmittingAssessment] =
    useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const categorisationStartedAtRef = useRef<string>()
  const learnMoreViewedAtRef = useRef<string>()

  const handleEndOfFlow = () => {
    const deepLinkRedirect = ConfigUtils.getDeepLinkRedirectForOrganisation(
      sessionStorage.getItem(SessionStorageKeys.OrganisationId)!,
    )

    const authCode = sessionStorage.getItem(SessionStorageKeys.AuthCode)

    if (deepLinkRedirect) {
      window.open(`${deepLinkRedirect}/${authCode}`, '_self')
    } else {
      // if no redirect, just prompt user to close the page
      setFlowEnded(true)
    }

    setLoading(false)
  }

  useAsyncEffect(async () => {
    try {
      // URL variables
      const userSignature = searchParams.get(SearchParams.UserSignature)
      const country = searchParams.get(SearchParams.Country)
      const authCode = searchParams.get(SearchParams.AuthCode)
      const codeVerifier = searchParams.get(SearchParams.CodeVerifier)
      const financialPromotionsId = searchParams.get(
        SearchParams.FinancialPromotionsId,
      )
      const organisationId = searchParams.get(SearchParams.OrganisationId)

      // Stored variables from session in case of page refresh
      const storedFinPromId = sessionStorage.getItem(
        SessionStorageKeys.FinancialPromotionId,
      )

      const storedAuthCode = sessionStorage.getItem(SessionStorageKeys.AuthCode)

      if (
        authCode &&
        codeVerifier &&
        country &&
        userSignature &&
        organisationId
      ) {
        // Store new variables from current session
        sessionStorage.setItem(
          SessionStorageKeys.OrganisationId,
          organisationId,
        )
        sessionStorage.setItem(SessionStorageKeys.AuthCode, authCode)
        sessionStorage.setItem(SessionStorageKeys.CodeVerifier, codeVerifier)

        // If financialPromotionsId is passed in URL, resume that flow
        if (financialPromotionsId) {
          const response = await enterpriseClient.getFinancialPromotion(
            financialPromotionsId,
          )
          /**
           * TODO : Remove once scaling work is finished
           *
           * Update if custom learnMoreUrl in orgConfig does not match current learnMoreUrl in risk-warning
           */
          const customLearnMoreUrl =
            ConfigUtils.getRiskWarningLinkForOrganisation(organisationId)
          if (
            customLearnMoreUrl !== response.riskWarning.learnMoreUrl &&
            response.riskWarning.status !== RiskWarningStatus.Accepted
          ) {
            const updatedResponse = await enterpriseClient.updateRiskWarning({
              financialPromotionsId,
              learnMoreUrl: customLearnMoreUrl,
            })
            return setFinancialPromotion(updatedResponse)
          }
          return setFinancialPromotion(response)
        }

        if (storedFinPromId && storedAuthCode === authCode) {
          // User is in the same flow, probably just refreshed the page, re-fetch the existing finProm
          const response = await enterpriseClient.getFinancialPromotion(
            storedFinPromId,
          )
          /**
           * TODO : Remove once scaling work is finished
           *
           * Update if custom learnMoreUrl in orgConfig does not match current learnMoreUrl in risk-warning
           */
          const customLearnMoreUrl =
            ConfigUtils.getRiskWarningLinkForOrganisation(organisationId)
          if (
            customLearnMoreUrl !== response.riskWarning.learnMoreUrl &&
            response.riskWarning.status !== RiskWarningStatus.Accepted
          ) {
            const updatedResponse = await enterpriseClient.updateRiskWarning({
              financialPromotionsId: storedFinPromId,
              learnMoreUrl: customLearnMoreUrl,
            })
            return setFinancialPromotion(updatedResponse)
          }
          return setFinancialPromotion(response)
        }

        const learnMoreUrl =
          ConfigUtils.getRiskWarningLinkForOrganisation(organisationId)
        const response = await enterpriseClient.initiateFinancialPromotionFlow({
          country,
          userSignature,
          learnMoreUrl,
        })
        setFinancialPromotion(response)
      } else {
        throw new Error('Bad URL')
      }
    } catch (e) {
      setError(true)
    }
  }, [])

  useEffect(() => {
    if (financialPromotion && !submittedAssessment) {
      setSubmittedAssessment(
        financialPromotion.appropriatenessAssessment?.attempt! > 1,
      )
    }
  }, [financialPromotion])

  const renderFinancialPromotionStage = useCallback(() => {
    if (!financialPromotion) {
      return (
        <ZContainer style={{ justifyContent: 'center' }}>
          <CircularProgress />
        </ZContainer>
      )
    }

    /* STEP 1 - Risk Warning */
    if (!financialPromotion?.riskWarning.acceptedAt) {
      if (declinedRiskWarning) {
        return (
          <CancelFlow
            body="If you skip these steps, this process will be cancelled and you will not be able to invest in cryptoassets."
            buttonText="Resume the process"
            cancelText="I don't want to go through this process"
            loading={loading}
            onDecision={async proceed => {
              try {
                setLoading(true)
                if (proceed) {
                  // let user re-do risk warning
                  setDeclinedRiskWarning(false)
                } else {
                  const response = await enterpriseClient.submitRiskWarning({
                    accepted: false,
                    learnMoreViewedAt: learnMoreViewedAtRef.current,
                  })

                  setFinancialPromotion(response)
                  handleEndOfFlow()
                }
              } catch (e) {
                handleError(e)
                setLoading(false)
              }
            }}
          />
        )
      }

      return (
        <RiskWarningForm
          riskWarning={financialPromotion.riskWarning}
          onDecision={async (accepted, learnMoreViewedAt) => {
            try {
              if (learnMoreViewedAt) {
                learnMoreViewedAtRef.current = learnMoreViewedAt
              }

              if (accepted) {
                setSubmittingRiskWarning(true)
                // Accept risk warning
                const response = await enterpriseClient.submitRiskWarning({
                  accepted: true,
                  learnMoreViewedAt: learnMoreViewedAtRef.current,
                  acceptedAt: new Date().toISOString(),
                })

                setFinancialPromotion(response)
                setSubmittingRiskWarning(false)
              } else {
                setDeclinedRiskWarning(true)
              }
            } catch (e) {
              handleError(e)
              setSubmittingRiskWarning(false)
            }
          }}
          loading={submittingRiskWarning}
          riskWarningUrl={ConfigUtils.getRiskWarningLinkForOrganisation(
            sessionStorage.getItem(SessionStorageKeys.OrganisationId)!,
          )}
        />
      )
    }

    /* STEP 2 - Categorisation */
    if (
      financialPromotion.categorisation?.categories &&
      !financialPromotion.categorisation?.signedAt
    ) {
      if (cancelCategorisation) {
        return (
          <CancelFlow
            body="If you skip these steps, this process will be cancelled and you will not be able to invest in cryptoassets."
            buttonText="Resume the process"
            cancelText="I don't want to go through this process"
            onDecision={async proceed => {
              if (proceed) {
                // let user re-do categorisation
                setCancelCategorisation(false)
              } else {
                handleEndOfFlow()
              }
            }}
          />
        )
      }

      if (!selectedCategory) {
        return (
          <CategorySelectionForm
            categories={financialPromotion.categorisation.categories}
            onSelectCategory={category => {
              categorisationStartedAtRef.current = new Date().toISOString()
              setSelectedCategory(category)
            }}
            onCancel={() => setCancelCategorisation(true)}
          />
        )
      }

      if (selectedCategory === CategorisationCategoryType.None) {
        return (
          <CancelFlow
            body={`As you have selected the category ‘None’, we strongly encourage you to consider if this type of investment is right for you and to seek financial advice.\n\nTo be able to invest in cryptoassets you must be a Restricted, High Net Worth or a Certified Sophisticated Investor.`}
            buttonText="Resubmit investor category"
            cancelText="I confirm I'm none of the above"
            loading={loading}
            onDecision={async proceed => {
              try {
                setLoading(true)

                if (proceed) {
                  // let user re-categorise
                  setSelectedCategory(undefined)
                  setLoading(false)
                } else {
                  const response = await enterpriseClient.submitCategorisation({
                    category: CategorisationCategoryType.None,
                    startedAt: categorisationStartedAtRef.current!,
                  })

                  setFinancialPromotion(response)
                  handleEndOfFlow()
                }
              } catch (e) {
                setLoading(false)
                handleError(e)
              }
            }}
          />
        )
      }

      return (
        <CategorisationForm
          category={
            financialPromotion.categorisation.categories.find(
              c => c.category === selectedCategory,
            )!
          }
          questions={financialPromotion.categorisation.questions.filter(
            q => q.category === selectedCategory,
          )}
          onComplete={async answers => {
            try {
              setSubmittingCategorisation(true)
              const response = await enterpriseClient.submitCategorisation({
                category: selectedCategory,
                startedAt: categorisationStartedAtRef.current!,
                signedAt: new Date().toISOString(),
                answers,
              })
              setFinancialPromotion(response)
              setSubmittingCategorisation(false)
            } catch (e) {
              handleError(e)
              setSubmittingCategorisation(false)
            }
          }}
          recategorise={() => setSelectedCategory(undefined)}
          loading={submittingCategorisation}
        />
      )
    }

    if (financialPromotion.appropriatenessAssessment?.questions.length) {
      if (!proceedToAssessment && !submittedAssessment) {
        return (
          <PreAppropriatenessAssessment
            onDecision={proceed => {
              if (proceed) {
                setProceedToAssessment(true)
              } else {
                handleEndOfFlow()
              }
            }}
            category={financialPromotion.categorisation?.category!}
            knowledgeHubUrl={ConfigUtils.getKnowledgeHubLinkForOrganisation(
              sessionStorage.getItem(SessionStorageKeys.OrganisationId)!,
            )}
          />
        )
      }

      if (!submittedAssessment) {
        return (
          <AppropriatenessAssessmentForm
            questions={financialPromotion.appropriatenessAssessment.questions}
            onBack={() => setProceedToAssessment(false)}
            onComplete={async answers => {
              try {
                setSubmittingAssessment(true)
                const response =
                  await enterpriseClient.submitAppropriatenessAssessment({
                    answers,
                  })
                setSubmittedAssessment(true)
                setFinancialPromotion(response)
                setSubmittingAssessment(false)
              } catch (e) {
                handleError(e)
                setSubmittingAssessment(false)
              }
            }}
            loading={submittingAssessment}
          />
        )
      }

      return (
        <AppropriatenessAssessmentCompleted
          assessment={financialPromotion.appropriatenessAssessment!}
          onDecision={retry => {
            if (retry) {
              setSubmittedAssessment(false)
            } else {
              handleEndOfFlow()
            }
          }}
          knowledgeHubUrl={ConfigUtils.getKnowledgeHubLinkForOrganisation(
            sessionStorage.getItem(SessionStorageKeys.OrganisationId)!,
          )}
        />
      )
    }
  }, [
    financialPromotion,
    selectedCategory,
    declinedRiskWarning,
    proceedToAssessment,
    submittedAssessment,
    cancelCategorisation,
    submittingCategorisation,
    submittingRiskWarning,
    submittingAssessment,
    loading,
  ])

  if (error) {
    return (
      <ZContainer>
        <ErrorScreen />
      </ZContainer>
    )
  }

  if (flowEnded) {
    return (
      <ZContainer>
        <CloseWebApp />
      </ZContainer>
    )
  }

  return <ZContainer>{renderFinancialPromotionStage()}</ZContainer>
}

export default Home
