import { createTheme } from '@mui/material'
import config from '../config'
import orgConfig from '../config/orgConfig'
import baseTheme from '../styles/theme'

export class ConfigUtils {
  static defaultKnowledgeHubUrl =
    orgConfig[config.zumoOrgId].links.knowledgeHub!

  static defaultTheme = orgConfig[config.zumoOrgId].theme

  static getRiskWarningLinkForOrganisation(organisationId: string): string {
    return orgConfig[organisationId].links.riskWarning
  }

  static getKnowledgeHubLinkForOrganisation(organisationId: string): string {
    const organisationConfig = orgConfig[organisationId]
    return organisationConfig.links.knowledgeHub || this.defaultKnowledgeHubUrl
  }

  static getThemeForOrganisation(organisationId: string) {
    const organisationConfig = orgConfig[organisationId]
    return organisationConfig ? organisationConfig.theme : this.defaultTheme
  }

  static createThemeForOrganisation(organisationId: string) {
    return createTheme({
      palette: {
        ...baseTheme.palette,
        ...this.getThemeForOrganisation(organisationId)?.palette,
      },
      typography: {
        ...baseTheme.typography,
      },
    })
  }

  static getDeepLinkRedirectForOrganisation(
    organisationId: string,
  ): string | undefined {
    return orgConfig[organisationId].links.deepLinkRedirect
  }
}
