import { Theme, useTheme } from '@mui/material'

const ZIcon = (props: any) => {
  const { style, Icon, ...rest } = props
  const theme = useTheme<Theme>()

  return (
    <div>
      <Icon
        fill={theme.palette.primary.main}
        style={{
          width: '9rem',
          marginBottom: '1rem',
          ...style,
        }}
        {...rest}
      />
    </div>
  )
}

export default ZIcon
