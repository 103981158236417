import { Container, ContainerProps } from '@mui/material'

const ZContainer = (props: ContainerProps) => {
  const { style, children, ...rest } = props

  return (
    <Container
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        width: '100%',
        paddingTop: '3rem',
        ...style,
      }}
      maxWidth="md"
      {...rest}
    >
      {children}
    </Container>
  )
}

export default ZContainer
