import { useLocation, useNavigate } from 'react-router-dom'
import ZIcon from '../components/ZIcon'
import { NotFoundIcon } from '../assets/icons'
import ZButton from '../components/ZButton'
import ZContainer from '../components/ZContainer'
import ZText from '../components/ZText'

const NotFound = () => {
  const navigate = useNavigate()
  const { search } = useLocation()

  return (
    <ZContainer>
      <ZIcon
        Icon={NotFoundIcon}
        style={{ width: '15rem', marginBottom: '2rem' }}
      />

      <ZText variant="h6">Page not found.</ZText>
    </ZContainer>
  )
}

export default NotFound
