import React, { useCallback, useState } from 'react'
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  Theme,
  styled,
  useTheme,
} from '@mui/material'
import { InputQuestionFormat } from '../enums'
import { unformatNumber } from '../util/number'
import ZText from './ZText'

interface ZTextFieldProps extends Omit<TextFieldProps<'standard'>, 'onChange'> {
  format?: InputQuestionFormat
  extraValidator?: string
  validatorError?: string
  onChange: (input: string) => void
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label': {
    color: theme.palette.text.disabled,
  },
  '& label.Mui-focused': {
    color: theme.palette.primary.main,
  },
}))

const ZTextField = (props: ZTextFieldProps) => {
  const theme = useTheme<Theme>()

  const { format, onChange, extraValidator, validatorError, ...rest } = props

  const [error, setError] = useState<string>()

  const handleFormat = useCallback((): {
    adornment?: string
    adornmentPosition?: 'start' | 'end'
    validator?: RegExp
    errorText?: string
    formatter?: (input: string) => string
  } => {
    switch (format) {
      case InputQuestionFormat.Currency:
        return {
          adornment: '£',
          adornmentPosition: 'start',
          validator:
            /^\d{1,3}(?:([., ])\d{3})*(?:\1\d{3})*(?:\1\d{2})?\d*(?:[.,]\d+)?$/g,
          errorText: 'Please enter a valid amount',
          formatter: input => unformatNumber(input),
        }
      case InputQuestionFormat.Percentage:
        return {
          adornment: '%',
          adornmentPosition: 'end',
          validator: /^-?\d+(\.\d+)?$/g,
          errorText: 'Value must be a number',
          formatter: input => unformatNumber(input),
        }
      case InputQuestionFormat.Number:
        return {
          validator: /^-?\d+(\.\d+)?$/g,
          errorText: 'Value must be a number',
          formatter: input => unformatNumber(input),
        }
      case InputQuestionFormat.Text:
        return {
          validator: undefined,
        }
      default:
        return {}
    }
  }, [format])

  const { adornment, adornmentPosition, validator, errorText, formatter } =
    handleFormat()

  return (
    <StyledTextField
      variant="standard"
      error={!!error}
      helperText={error}
      onChange={event => {
        if (validator) {
          if (event.target.value.length) {
            const isInputValid = !!event.target.value.match(validator)

            if (!isInputValid) {
              setError(errorText)
              // Override onChange to disable button and not send invalid value
              return onChange('')
            }

            if (extraValidator) {
              const regex = new RegExp(extraValidator, 'g')

              const unformattedNumber = unformatNumber(event.target.value)

              const isFormattedInputValid = !!unformattedNumber
                .toString()
                .match(regex)

              if (!isFormattedInputValid) {
                setError(validatorError || 'Invalid value')
                return onChange('')
              }
            }

            setError(undefined)
          }

          setError(undefined)
        }

        if (formatter && event.target.value.length) {
          const formattedValue = formatter(event.target.value)
          return onChange(formattedValue)
        }

        onChange(event.target.value)
      }}
      {...rest}
      InputProps={{
        startAdornment:
          adornment && adornmentPosition === 'start' ? (
            <InputAdornment position="start">
              <ZText color={theme.palette.text.primary}>{adornment}</ZText>
            </InputAdornment>
          ) : null,
        endAdornment:
          adornment && adornmentPosition === 'end' ? (
            <InputAdornment position="start">
              <ZText color={theme.palette.text.primary}>{adornment}</ZText>
            </InputAdornment>
          ) : null,
      }}
    />
  )
}

export default ZTextField
