import { useCallback, useRef, useState } from 'react'
import { Typography } from '@mui/material'
import {
  AppropriatenessAssessmentQuestion,
  CategorisationQuestion,
  CategorisationQuestionOption,
  FollowUpCategorisationQuestion,
  InputQuestion,
  QuestionAnswer,
} from '../types'
import ZRadio from './ZRadio'
import { QuestionType } from '../enums'
import ZTextField from './ZTextField'
import ZText from './ZText'

const Question = ({
  question,
  onAnswer,
  initialAnswer,
}: {
  question:
    | CategorisationQuestion
    | FollowUpCategorisationQuestion
    | AppropriatenessAssessmentQuestion
  onAnswer: (
    answeredQuestion: Record<QuestionAnswer['code'], QuestionAnswer['answer']>,
  ) => void
  initialAnswer?: QuestionAnswer['answer']
}) => {
  const [answer, setAnswer] = useState<CategorisationQuestion['answer']>(null)

  const answersRef = useRef<
    Record<QuestionAnswer['code'], QuestionAnswer['answer']>
  >({})

  const checkForFollowUpQuestion = (
    followUpValue: CategorisationQuestion['answer'],
  ) => {
    const selectedOption = question.options!.find(
      option => option.value === followUpValue,
    )

    return (question as CategorisationQuestion).followUpQuestions?.find(
      q => q.parentValue === selectedOption?.value,
    )
  }

  const renderFollowUpQuestion = useCallback(
    (followUpQuestion?: FollowUpCategorisationQuestion) => {
      if (followUpQuestion) {
        return (
          <Question
            key={`${answer}`}
            question={followUpQuestion}
            onAnswer={answeredQuestion => {
              answersRef.current = {
                ...answersRef.current,
                ...answeredQuestion,
              }

              onAnswer(answersRef.current)
            }}
          />
        )
      }
    },
    [answer],
  )

  switch (question.type) {
    case QuestionType.Select:
      return (
        <div
          style={{ display: 'flex', rowGap: '1rem', flexDirection: 'column' }}
        >
          <div>
            <ZText style={{ marginBottom: '2rem' }}>{question.label}</ZText>

            <ZRadio
              style={{ rowGap: '1rem' }}
              options={question.options!.map(option => ({
                value: option.value,
                label: option.label,
                checked: initialAnswer
                  ? option.value === initialAnswer
                  : undefined,
              }))}
              onChange={event => {
                setAnswer(event.target.value)

                const followUpQuestion = checkForFollowUpQuestion(
                  event.target.value,
                )

                if (followUpQuestion) {
                  answersRef.current = {}
                  answersRef.current[question.code] = event.target.value
                  answersRef.current[followUpQuestion.code] = ''
                } else {
                  answersRef.current = {
                    [question.code]: event.target.value,
                  }
                }

                onAnswer(answersRef.current)
              }}
            />
          </div>

          <div>{renderFollowUpQuestion(checkForFollowUpQuestion(answer))}</div>
        </div>
      )
    case QuestionType.Input:
      return (
        <div>
          <ZText style={{ marginBottom: '0.5rem' }}>{question.label}</ZText>

          <ZTextField
            label="Insert value"
            format={(question as InputQuestion).format}
            extraValidator={(question as InputQuestion)?.validRegex}
            validatorError={(question as InputQuestion)?.validatorError}
            fullWidth
            onChange={input => {
              answersRef.current[question.code] = input

              onAnswer(answersRef.current)
            }}
          />
        </div>
      )
    default:
      return null
  }
}

export default Question
