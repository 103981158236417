import { ApiHeaders, FetchOptions } from '../types'

class ApiClient {
  private BASE_URL: string

  private headers: ApiHeaders = {
    'Content-Type': 'application/json',
  }

  constructor(baseUrl: string) {
    this.BASE_URL = baseUrl
  }

  public async request<T>(path: string, options: FetchOptions): Promise<T> {
    const response = await fetch(`${this.BASE_URL}${path}`, {
      method: options.method,
      body: options.body ? JSON.stringify(options.body) : undefined,
      headers: {
        ...this.headers,
        ...options.headers,
      },
    })

    if (!response.ok) {
      try {
        const errorResponse = await response.json()
        throw errorResponse
      } catch (error) {
        throw Error('Something went wrong')
      }
    }

    if (response.status === 204) {
      return undefined as T
    }

    const json = await response.json()

    return json as T
  }
}

export default ApiClient
