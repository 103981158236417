import { CircularProgress } from '@mui/material'
import ZIcon from '../components/ZIcon'
import { CancelFlowIcon } from '../assets/icons'
import ZButton from '../components/ZButton'
import ZText from '../components/ZText'
import ZBottomContainer from '../components/ZBottomContainer'
import ZScreenContainer from '../components/ZScreenContainer'
import ZContainer from '../components/ZContainer'

const CancelFlow = ({
  body,
  buttonText,
  cancelText,
  onDecision,
  loading,
}: {
  body: string
  buttonText: string
  cancelText: string
  onDecision: (proceed: boolean) => void
  loading?: boolean
}) => {
  return (
    <ZScreenContainer>
      <ZIcon
        Icon={CancelFlowIcon}
        title="Cancel icon"
        style={{ alignSelf: 'center' }}
      />

      <ZText textAlign="center" whiteSpace="pre-wrap">
        {body}
      </ZText>

      <ZBottomContainer>
        {loading ? (
          <CircularProgress size={50} />
        ) : (
          <>
            <ZButton onClick={() => onDecision(true)}>{buttonText}</ZButton>

            <ZButton variant="text" onClick={() => onDecision(false)}>
              {cancelText}
            </ZButton>
          </>
        )}
      </ZBottomContainer>
    </ZScreenContainer>
  )
}

export default CancelFlow
