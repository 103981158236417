import config from '../config'
import ApiClient from './ApiClient'
import { SessionStorageKeys } from '../enums'
import {
  ApiHeaders,
  Categorisation,
  FinancialPromotion,
  QuestionAnswer,
  RiskWarning,
  Token,
} from '../types'
import { ConfigUtils } from '../util/configUtils'

class EnterpriseClient extends ApiClient {
  private async getAccessToken(
    authCode?: string | null,
    codeVerifier?: string | null,
  ) {
    const storedAccessToken = sessionStorage.getItem(
      SessionStorageKeys.AccessToken,
    )

    if (storedAccessToken) {
      return storedAccessToken
    }

    if (!authCode || !codeVerifier) {
      throw new Error('Missing parameters')
    }

    const token = await this.request<Token>('/v1/token', {
      method: 'POST',
      body: {
        grant_type: 'authorization_code',
        code: authCode,
        code_verifier: codeVerifier,
      },
    })

    sessionStorage.setItem(SessionStorageKeys.AccessToken, token.accessToken)

    return token.accessToken
  }

  private async generateHeaders(): Promise<ApiHeaders> {
    const authCode = sessionStorage.getItem(SessionStorageKeys.AuthCode)
    const codeVerifier = sessionStorage.getItem(SessionStorageKeys.CodeVerifier)

    const accessToken = await this.getAccessToken(authCode, codeVerifier)

    return {
      Authorization: `Bearer ${accessToken}`,
    }
  }

  public async initiateFinancialPromotionFlow({
    country,
    userSignature,
    learnMoreUrl,
  }: {
    country: string
    userSignature: string
    learnMoreUrl: string
  }) {
    const headers = await this.generateHeaders()

    const response = await this.request<FinancialPromotion>(
      '/v1/financial-promotions',
      {
        method: 'POST',
        body: {
          country,
          userSignature,
          learnMoreUrl,
        },
        headers,
      },
    )

    sessionStorage.setItem(SessionStorageKeys.FinancialPromotionId, response.id)

    return response
  }

  public async getFinancialPromotion(financialPromotionsId: string) {
    const headers = await this.generateHeaders()

    const response = await this.request<FinancialPromotion>(
      `/v1/financial-promotions/${financialPromotionsId}`,
      {
        method: 'GET',
        headers,
      },
    )

    sessionStorage.setItem(
      SessionStorageKeys.FinancialPromotionId,
      financialPromotionsId,
    )

    return response
  }

  public async submitRiskWarning({
    accepted,
    acceptedAt,
    learnMoreViewedAt,
  }: {
    accepted: boolean
    acceptedAt?: RiskWarning['acceptedAt']
    learnMoreViewedAt?: RiskWarning['learnMoreViewedAt']
  }) {
    const headers = await this.generateHeaders()

    const financialPromotionId = sessionStorage.getItem(
      SessionStorageKeys.FinancialPromotionId,
    )

    const learnMoreUrl = ConfigUtils.getRiskWarningLinkForOrganisation(
      sessionStorage.getItem(SessionStorageKeys.OrganisationId)!,
    )

    const response = await this.request<FinancialPromotion>(
      `/v1/financial-promotions/${financialPromotionId}/risk-warning`,
      {
        method: 'POST',
        body: {
          accepted,
          acceptedAt,
          learnMoreViewedAt,
          learnMoreUrl,
        },
        headers,
      },
    )

    return response
  }

  /**
   * TODO : Remove once scaling work is finished
   */
  public async updateRiskWarning({
    financialPromotionsId,
    learnMoreUrl,
  }: {
    financialPromotionsId: string
    learnMoreUrl?: string
  }) {
    const headers = await this.generateHeaders()

    const response = await this.request<FinancialPromotion>(
      `/v1/financial-promotions/${financialPromotionsId}/risk-warning`,
      {
        method: 'PATCH',
        body: {
          learnMoreUrl,
        },
        headers,
      },
    )

    return response
  }

  public async submitCategorisation({
    category,
    startedAt,
    signedAt,
    answers,
  }: {
    category: Categorisation['category']
    startedAt: Categorisation['startedAt']
    signedAt?: Categorisation['signedAt']
    answers?: QuestionAnswer[]
  }) {
    const headers = await this.generateHeaders()

    const financialPromotionId = sessionStorage.getItem(
      SessionStorageKeys.FinancialPromotionId,
    )

    const response = await this.request<FinancialPromotion>(
      `/v1/financial-promotions/${financialPromotionId}/categorisation`,
      {
        method: 'POST',
        body: {
          category,
          startedAt,
          signedAt,
          answers,
        },
        headers,
      },
    )

    return response
  }

  public async submitAppropriatenessAssessment({
    answers,
  }: {
    answers: QuestionAnswer[]
  }) {
    const headers = await this.generateHeaders()

    const financialPromotionId = sessionStorage.getItem(
      SessionStorageKeys.FinancialPromotionId,
    )

    const response = await this.request<FinancialPromotion>(
      `/v1/financial-promotions/${financialPromotionId}/appropriateness-assessment`,
      {
        method: 'POST',
        body: {
          answers,
        },
        headers,
      },
    )

    return response
  }
}

const enterpriseClient = new EnterpriseClient(config.baseUrl)

export default enterpriseClient
