import React from 'react'
import { LinearProgress, LinearProgressProps } from '@mui/material'

const ZProgressBar = (props: LinearProgressProps) => {
  const { style, ...rest } = props

  return (
    <LinearProgress
      variant="determinate"
      color="primary"
      style={{ ...style, height: 7, borderRadius: 9 }}
      {...rest}
    />
  )
}

export default ZProgressBar
