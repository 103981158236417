import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Theme,
  useTheme,
} from '@mui/material'
import ZText from './ZText'

interface ZRadioProps extends RadioGroupProps {
  options: Array<{
    value: string
    label: string
    subLabel?: string
    checked?: boolean
  }>
  box?: boolean
}

const ZRadio = (props: ZRadioProps) => {
  const theme = useTheme<Theme>()

  const { box, options, ...rest } = props

  return (
    <RadioGroup {...rest}>
      {options.map(option => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={
            <Radio
              checked={option.checked}
              style={{
                color: theme.palette.primary.main,
              }}
            />
          }
          label={
            <>
              <ZText>{option.label}</ZText>
              {option.subLabel ? (
                <ZText style={{ fontWeight: '300' }} variant="body2">
                  {option.subLabel}
                </ZText>
              ) : null}
            </>
          }
          style={
            box
              ? {
                  borderWidth: '1px',
                  borderColor: theme.palette.primary.main,
                  borderStyle: 'solid',
                  borderRadius: 10,
                  padding: '1rem 1rem 1rem 1rem',
                  margin: '0 0 1rem 0',
                  width: '100%',
                }
              : {
                  margin: 0,
                }
          }
        />
      ))}
    </RadioGroup>
  )
}

export default ZRadio
