import { useState } from 'react'
import { Theme, useTheme } from '@mui/material'
import ZRadio from '../components/ZRadio'
import ZButton from '../components/ZButton'
import { CategorySelectionIcon } from '../assets/icons'
import ZIcon from '../components/ZIcon'
import { CategorisationCategory } from '../types'
import { CategorisationCategoryType } from '../enums'
import ZText from '../components/ZText'
import ZBottomContainer from '../components/ZBottomContainer'
import ZScreenContainer from '../components/ZScreenContainer'
import Header from '../components/Header'

const CategorySelectionForm = ({
  categories,
  onSelectCategory,
  onCancel,
}: {
  categories: CategorisationCategory[]
  onSelectCategory: (category: CategorisationCategoryType) => void
  onCancel: () => void
}) => {
  const [selectedCategory, setSelectedCategory] =
    useState<CategorisationCategoryType>()

  const theme = useTheme<Theme>()

  return (
    <ZScreenContainer>
      <Header title="Self Categorisation" />

      <ZIcon
        Icon={CategorySelectionIcon}
        fill="none"
        stroke={theme.palette.primary.main}
        title="Risk warning icon"
        style={{ alignSelf: 'center' }}
      />

      <ZText>
        Next we’ll ask you to confirm which category of investor you belong to.
        <br />
        <br />I would classify myself as the following type of investor:
      </ZText>

      <ZRadio
        box
        options={categories.map(category => ({
          value: category.category,
          label: category.label,
          subLabel: category.subLabel,
        }))}
        onChange={event =>
          setSelectedCategory(event.target.value as CategorisationCategoryType)
        }
        style={{ marginTop: '1rem' }}
      />

      <ZBottomContainer>
        <ZButton
          onClick={() => onSelectCategory(selectedCategory!)}
          disabled={!selectedCategory}
        >
          Next
        </ZButton>

        <ZButton variant="text" onClick={() => onCancel()}>
          I don&apos;t want to go through this process
        </ZButton>
      </ZBottomContainer>
    </ZScreenContainer>
  )
}

export default CategorySelectionForm
