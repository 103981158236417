import { CircularProgress, Container, LinearProgress } from '@mui/material'
import { FC, SVGProps, useState } from 'react'
import { AppropriatenessAssessmentQuestion, QuestionAnswer } from '../types'
import ZProgressBar from '../components/ZProgressBar'
import Question from '../components/Question'
import ZButton from '../components/ZButton'
import ZIcon from '../components/ZIcon'
import ZBottomContainer from '../components/ZBottomContainer'
import ZContainer from '../components/ZContainer'
import ZScreenContainer from '../components/ZScreenContainer'
import Header from '../components/Header'
import {
  AppropriatenessAssessmentQuestionOne,
  AppropriatenessAssessmentQuestionTwo,
  AppropriatenessAssessmentQuestionThree,
  AppropriatenessAssessmentQuestionFour,
  AppropriatenessAssessmentQuestionFive,
  AppropriatenessAssessmentQuestionSix,
  AppropriatenessAssessmentQuestionSeven,
  AppropriatenessAssessmentQuestionEight,
  AppropriatenessAssessmentQuestionNine,
  AppropriatenessAssessmentQuestionTen,
  AppropriatenessAssessmentQuestionEleven,
  AppropriatenessAssessmentQuestionTwelve,
} from '../assets/icons'

type QuestionAnswerState = Record<
  QuestionAnswer['code'],
  QuestionAnswer['answer']
>

const appropriatenessAssessmentQuestionIconMap: FC<SVGProps<SVGSVGElement>>[] =
  [
    AppropriatenessAssessmentQuestionOne,
    AppropriatenessAssessmentQuestionTwo,
    AppropriatenessAssessmentQuestionThree,
    AppropriatenessAssessmentQuestionFour,
    AppropriatenessAssessmentQuestionFive,
    AppropriatenessAssessmentQuestionSix,
    AppropriatenessAssessmentQuestionSeven,
    AppropriatenessAssessmentQuestionEight,
    AppropriatenessAssessmentQuestionNine,
    AppropriatenessAssessmentQuestionTen,
    AppropriatenessAssessmentQuestionEleven,
    AppropriatenessAssessmentQuestionTwelve,
  ]

const AppropriatenessAssessmentForm = ({
  questions,
  onComplete,
  onBack,
  loading,
}: {
  questions: AppropriatenessAssessmentQuestion[]
  onComplete: (answers: QuestionAnswer[]) => void
  onBack: () => void
  loading: boolean
}) => {
  const [questionAnswers, setQuestionAnswers] = useState<
    Record<number, QuestionAnswerState>
  >({})

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0)

  if (currentQuestionIndex + 1 > questions.length) {
    return (
      <ZContainer style={{ justifyContent: 'center' }}>
        <CircularProgress />
      </ZContainer>
    )
  }

  return (
    <ZScreenContainer>
      <Header
        title="Appropriateness Assessment"
        onBack={() => {
          if (currentQuestionIndex === 0) {
            onBack()
          } else {
            setCurrentQuestionIndex(currentQuestionIndex - 1)
          }
        }}
      />
      <div style={{ width: '100%' }}>
        <ZProgressBar value={(currentQuestionIndex / questions.length) * 100} />
      </div>

      <ZIcon
        Icon={appropriatenessAssessmentQuestionIconMap[currentQuestionIndex]}
        title="assessment question icon"
        style={{ width: '6rem', alignSelf: 'center', margin: '1rem 0' }}
      />

      <Question
        key={currentQuestionIndex}
        question={questions[currentQuestionIndex]}
        initialAnswer={
          questionAnswers?.[currentQuestionIndex + 1]?.[
            questions[currentQuestionIndex].code
          ]
        }
        onAnswer={answeredQuestion => {
          setQuestionAnswers({
            ...questionAnswers,
            [currentQuestionIndex + 1]: answeredQuestion as QuestionAnswer,
          })
        }}
      />

      <ZBottomContainer>
        <ZButton
          onClick={() => {
            setCurrentQuestionIndex(currentQuestionIndex + 1)

            if (currentQuestionIndex + 1 === questions.length) {
              // all questions answered
              onComplete(
                Object.values(questionAnswers).flatMap(question =>
                  Object.entries(question).map(([key, value]) => ({
                    code: key,
                    answer: value,
                  })),
                ),
              )
            }
          }}
          disabled={!questionAnswers[currentQuestionIndex + 1]}
          loading={loading}
        >
          {currentQuestionIndex + 1 === questions.length ? 'Submit' : 'Next'}
        </ZButton>
      </ZBottomContainer>
    </ZScreenContainer>
  )
}

export default AppropriatenessAssessmentForm
