import { Container } from '@mui/material'
import { ReactNode } from 'react'

const ZBottomContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flex: 1,
        paddingBottom: '2rem',
      }}
      maxWidth="md"
    >
      {children}
    </Container>
  )
}

export default ZBottomContainer
