import { Link } from '@mui/material'
import { useState } from 'react'
import { RiskWarningIcon } from '../assets/icons'
import ZIcon from '../components/ZIcon'
import { RiskWarning } from '../types'
import ZButton from '../components/ZButton'
import ZText from '../components/ZText'
import ZBottomContainer from '../components/ZBottomContainer'
import ZScreenContainer from '../components/ZScreenContainer'
import Header from '../components/Header'

const RiskWarningForm = ({
  riskWarning,
  onDecision,
  loading,
  riskWarningUrl,
}: {
  riskWarning: RiskWarning
  onDecision: (accepted: boolean, learnMoreViewedAt?: string) => void
  loading: boolean
  riskWarningUrl: string
}) => {
  const [learnMoreViewedAt, setLearnMoreViewedAt] = useState<string>()

  return (
    <ZScreenContainer>
      <Header title="Risk Information" />

      <ZIcon
        Icon={RiskWarningIcon}
        title="Risk warning icon"
        style={{ alignSelf: 'center' }}
      />

      <ZText>
        {riskWarning.message}
        <br />
        <br />
        <Link
          href={riskWarningUrl}
          underline="none"
          target="_blank"
          onClick={() => setLearnMoreViewedAt(new Date().toISOString())}
        >
          Take 2 minutes to learn more
        </Link>
      </ZText>

      <ZBottomContainer>
        <ZButton
          onClick={() => onDecision(true, learnMoreViewedAt)}
          loading={loading}
        >
          Continue
        </ZButton>

        <ZButton
          variant="text"
          onClick={() => onDecision(false)}
          disabled={loading}
        >
          I don&apos;t want to go through this process
        </ZButton>
      </ZBottomContainer>
    </ZScreenContainer>
  )
}

export default RiskWarningForm
