class Config {
  baseUrl: string = process.env.REACT_APP_BASE_URL!

  zumoOrgId: string = process.env.REACT_APP_ZUMO_ORG_ID!

  manualTestingOrgId: string = process.env.REACT_APP_MANUAL_TESTING_ORG_ID!
}

const config = new Config()

export default config
