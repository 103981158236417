import { Typography } from '@mui/material'
import ZButton from '../components/ZButton'
import ZIcon from '../components/ZIcon'
import { SkipAppropriatenessAssessmentIcon } from '../assets/icons'
import ZBottomContainer from '../components/ZBottomContainer'
import ZScreenContainer from '../components/ZScreenContainer'

const SkipAppropriatenessAssessment = ({
  onDecision,
}: {
  onDecision: (proceed: boolean) => void
}) => {
  return (
    <ZScreenContainer>
      <ZIcon
        Icon={SkipAppropriatenessAssessmentIcon}
        title="Skip appropriateness assessment icon"
        style={{ alignSelf: 'center' }}
      />

      <Typography>
        You need to complete this assessment to be able to invest in
        cryptoassets.
      </Typography>

      <ZBottomContainer>
        <ZButton onClick={() => onDecision(true)}>Start the assessment</ZButton>

        <ZButton variant="text" onClick={() => onDecision(false)}>
          Skip for now
        </ZButton>
      </ZBottomContainer>
    </ZScreenContainer>
  )
}

export default SkipAppropriatenessAssessment
