import { createTheme } from '@mui/material'

const baseTheme = createTheme({
  /* COLORS */
  palette: {
    primary: {
      main: '#E93CAC',
    },
    secondary: {
      main: '#E93CAC',
    },
    success: {
      main: '#00C389',
    },
    error: {
      main: '#F8485E',
    },
    warning: {
      main: '#FCBA03',
    },
    background: {
      default: '#1D242A',
      paper: '#242D33',
    },
    text: {
      disabled: '#495A67',
      primary: '#BDBDBD',
      secondary: '#000000',
    },
    divider: '#242D33',
    mode: 'dark',
  },
  /* TYPOGRAPHY */
  typography: {
    fontFamily: 'Nunito-Sans',
    button: {
      textTransform: 'none',
      fontWeight: '600',
    },
  },
})

export default baseTheme
