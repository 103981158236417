import replace from 'react-string-replace'

export const formatString = (text: string) => {
  let formattedText

  // Bold = *
  formattedText = replace(text, /\*([^*]+)\*/g, (match, index) => (
    <b key={index + match}>{formatString(match)}</b>
  ))

  // Italic = ~
  formattedText = replace(formattedText, /~([^~]+)~/g, (match, index) => (
    <i key={index + match}>{formatString(match)}</i>
  ))

  // Underline = _
  formattedText = replace(formattedText, /_([^_]+)_/g, (match, index) => (
    <u key={index + match}>{formatString(match)}</u>
  ))

  return formattedText
}
