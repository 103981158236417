import { Typography } from '@mui/material'
import ZIcon from '../components/ZIcon'
import { ExitIcon } from '../assets/icons'
import ZScreenContainer from '../components/ZScreenContainer'
import { ConfigUtils } from '../util/configUtils'
import { SessionStorageKeys } from '../enums'
import ZBottomContainer from '../components/ZBottomContainer'
import ZButton from '../components/ZButton'

const CloseWebApp = () => {
  const deepLinkRedirect = ConfigUtils.getDeepLinkRedirectForOrganisation(
    sessionStorage.getItem(SessionStorageKeys.OrganisationId)!,
  )

  const authCode = sessionStorage.getItem(SessionStorageKeys.AuthCode)

  return (
    <ZScreenContainer>
      <ZIcon Icon={ExitIcon} title="Exit icon" />

      {deepLinkRedirect ? (
        <>
          <Typography>
            Please press the button below to return to the app
          </Typography>

          <ZBottomContainer>
            <ZButton
              onClick={() =>
                window.open(`${deepLinkRedirect}/${authCode}`, '_self')
              }
            >
              Return to the app
            </ZButton>
          </ZBottomContainer>
        </>
      ) : (
        <Typography>Please close this screen.</Typography>
      )}
    </ZScreenContainer>
  )
}

export default CloseWebApp
