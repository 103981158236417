import { Route, Routes } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { Theme, useTheme } from '@mui/material'
import Home from './routes/home'
import NotFound from './routes/not-found'

export default function App() {
  const theme = useTheme<Theme>()

  return (
    <div>
      <Routes>
        <Route index element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Toaster
        toastOptions={{
          style: {
            background: theme.palette.background.paper,
            color: theme.palette.text.primary,
          },
          success: {
            iconTheme: {
              primary: theme.palette.success.main,
              secondary: theme.palette.common.white,
            },
          },
          error: {
            iconTheme: {
              primary: theme.palette.error.main,
              secondary: theme.palette.common.white,
            },
          },
        }}
      />
    </div>
  )
}
