import React from 'react'
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormGroup,
  styled,
} from '@mui/material'
import ZText from './ZText'

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
  alignSelf: 'flex-start',
  marginTop: '-6px',
  marginRight: '0.5rem',
}))

const ZCheckbox = (props: CheckboxProps & { label: string }) => {
  const { label, ...rest } = props

  return (
    <FormGroup>
      <FormControlLabel
        label={<ZText>{label}</ZText>}
        control={<StyledCheckBox {...rest} />}
        style={{ textAlign: 'left' }}
      />
    </FormGroup>
  )
}

export default ZCheckbox
