import { Theme, useTheme } from '@mui/material'
import { FC, SVGProps, useCallback, useEffect, useRef, useState } from 'react'
import ZButton from '../components/ZButton'
import {
  CategorisationCategory,
  CategorisationQuestion,
  FollowUpCategorisationQuestion,
  QuestionAnswer,
} from '../types'
import Question from '../components/Question'
import ZCheckbox from '../components/ZCheckbox'
import { CategorisationCategoryType } from '../enums'
import ZIcon from '../components/ZIcon'
import ZText from '../components/ZText'
import ZBottomContainer from '../components/ZBottomContainer'
import ZScreenContainer from '../components/ZScreenContainer'
import Header from '../components/Header'
import { capitalizeWords } from '../util/text'
import {
  RestrictedInvestorIcon,
  HighNetWorthInvestorIcon,
  CertifiedSophisticatedInvestorIcon,
  ErrorIcon,
} from '../assets/icons'

const categoryIcons: Record<
  CategorisationCategoryType,
  FC<SVGProps<SVGSVGElement>>
> = {
  [CategorisationCategoryType.RestrictedInvestor]: RestrictedInvestorIcon,
  [CategorisationCategoryType.HighNetWorthInvestor]: HighNetWorthInvestorIcon,
  [CategorisationCategoryType.CertifiedSophisticatedInvestor]:
    CertifiedSophisticatedInvestorIcon,
  [CategorisationCategoryType.None]: ErrorIcon,
}

type QuestionAnswerState = Record<
  QuestionAnswer['code'],
  QuestionAnswer['answer']
>

const CategorisationForm = ({
  category,
  questions,
  onComplete,
  recategorise,
  loading,
}: {
  category: CategorisationCategory
  questions: CategorisationQuestion[]
  onComplete: (answers: QuestionAnswer[]) => void
  recategorise: () => void
  loading: boolean
}) => {
  const theme = useTheme<Theme>()

  const initialQuestionAnswersState = questions.reduce(
    (acc: Record<number, QuestionAnswerState>, curr, index) => {
      acc[index + 1] = { answer: '', code: curr.code }
      return acc
    },
    {},
  )

  const [acceptedDeclaration, setAcceptedDeclaration] = useState<boolean>(false)

  const [questionAnswers, setQuestionAnswers] = useState<
    Record<number, QuestionAnswerState>
  >(initialQuestionAnswersState)

  const questionAnswersRef = useRef<Record<number, QuestionAnswerState>>(
    initialQuestionAnswersState,
  )

  useEffect(() => {
    // if answers change, uncheck declaration
    if (acceptedDeclaration) {
      setAcceptedDeclaration(false)
    }
  }, [questionAnswers])

  const disabled = useCallback(() => {
    const questionCompleted = (question: QuestionAnswerState) => {
      return Object.keys(question).every(key => {
        const answer = question[key]

        const flattenQuestions = (
          qs: Array<CategorisationQuestion | FollowUpCategorisationQuestion>,
        ) =>
          qs.reduce(
            (
              acc: Array<
                CategorisationQuestion | FollowUpCategorisationQuestion
              >,
              curr: CategorisationQuestion | FollowUpCategorisationQuestion,
            ) => {
              acc.push(curr)

              if (curr.followUpQuestions) {
                acc.push(...flattenQuestions(curr.followUpQuestions))
              }

              return acc
            },
            [],
          )

        const flattenedQuestions = flattenQuestions(questions)

        const isAnswerInvalid =
          flattenedQuestions
            .find(q => q.code === key)
            ?.options?.find(option => option.value === question[key])
            ?.isValid === false

        return !!answer && !isAnswerInvalid
      })
    }

    const formCompleted = Object.keys(questionAnswersRef.current).every(key => {
      const question = questionAnswersRef.current[Number(key)]

      return questionCompleted(question)
    })

    return !formCompleted
  }, [questionAnswers])

  return (
    <ZScreenContainer>
      <Header
        title={capitalizeWords(category.category.replaceAll('-', ' '))}
        onBack={() => recategorise()}
      />

      <ZIcon
        Icon={categoryIcons[category.category]}
        title="categorisation icon"
        style={{ alignSelf: 'center' }}
      />

      {category.statement ? <ZText>{category.statement}</ZText> : null}

      <div
        style={{
          borderColor: theme.palette.primary.main,
          borderWidth: 1,
          borderStyle: 'solid',
          padding: '1.5rem',
          rowGap: '1rem',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <>
          {category.preQuestionStatement ? (
            <ZText>{category.preQuestionStatement}</ZText>
          ) : null}

          {questions.map((question, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '0.8rem',
              }}
            >
              {question.category ===
              CategorisationCategoryType.RestrictedInvestor ? (
                <ZText fontWeight="bold">
                  {String.fromCharCode(index + 1 + 64)}
                </ZText>
              ) : null}

              <Question
                question={question}
                onAnswer={answeredQuestion => {
                  // Assign to ref as state sometimes isn't fast enough
                  questionAnswersRef.current = {
                    ...questionAnswersRef.current,
                    [index + 1]: answeredQuestion,
                  }
                  setQuestionAnswers({
                    ...questionAnswersRef.current,
                    [index + 1]: answeredQuestion,
                  })
                }}
              />
            </div>
          ))}
        </>
      </div>

      <ZBottomContainer>
        <ZCheckbox
          label={category.declaration!}
          onChange={event => setAcceptedDeclaration(event.target.checked)}
          checked={!!acceptedDeclaration}
          disabled={disabled()}
        />

        <ZButton
          style={{ marginTop: '1rem' }}
          loading={loading}
          onClick={() => {
            // Convert all answers to an array
            onComplete(
              Object.values(questionAnswersRef.current).flatMap(question =>
                Object.entries(question).map(([key, value]) => ({
                  code: key,
                  answer: value,
                })),
              ),
            )
          }}
          disabled={disabled() || !acceptedDeclaration}
        >
          Next
        </ZButton>

        <ZButton
          variant="text"
          disabled={loading}
          onClick={() => recategorise()}
        >
          Choose a different category
        </ZButton>
      </ZBottomContainer>
    </ZScreenContainer>
  )
}

export default CategorisationForm
