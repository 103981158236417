import React, { useMemo } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, useSearchParams } from 'react-router-dom'
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material'
import App from './App'
import './styles/fonts.css'
import { ConfigUtils } from './util/configUtils'
import { SearchParams } from './enums/SearchParams'

function Root() {
  const theme = useMemo(() => {
    const searchParams = window.location.search
    const params = new URLSearchParams(searchParams)
    const orgId = params.get(SearchParams.OrganisationId)
    return ConfigUtils.createThemeForOrganisation(orgId!)
  }, [])

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter basename="/financial-promotions">
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  )
}

ReactDOM.createRoot(document.getElementById('root')!).render(<Root />)
