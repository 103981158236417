import { Typography } from '@mui/material'
import ZIcon from './ZIcon'
import { ChevronLeft } from '../assets/icons'

const Header = ({ title, onBack }: { title: string; onBack?: () => void }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '1.5rem',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        marginBottom: '1.5rem',
      }}
    >
      {onBack ? (
        <ZIcon
          Icon={ChevronLeft}
          title="Back button"
          style={{
            width: '1.5rem',
            height: '1.5rem',
            position: 'absolute',
            left: 0,
            top: 0,
            cursor: 'pointer',
          }}
          onClick={() => onBack()}
        />
      ) : null}

      <Typography textAlign="center">{title}</Typography>
    </div>
  )
}

export default Header
