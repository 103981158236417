import config from '.'
import { OrgConfig } from '../types/OrgConfig'

const orgConfig: OrgConfig = {
  [config.zumoOrgId]: {
    theme: {
      palette: {
        primary: { main: '#E93CAC' },
        background: { default: '#1D242A' },
        text: {
          disabled: '#495A67',
          primary: '#BDBDBD',
        },
      },
    },
    links: {
      riskWarning: 'https://app.zumo.tech/zumo-risk-information',
      knowledgeHub: 'https://app.zumo.tech/learn',
      deepLinkRedirect: 'zumo://dlink/financial-promotions-web-app',
    },
  },
  [config.manualTestingOrgId]: {
    theme: {
      palette: {
        primary: { main: '#009DDC' },
        background: { default: '#1D242A' },
      },
    },
    links: {
      riskWarning: 'test.com',
      knowledgeHub: 'test.com',
    },
  },
}

export default orgConfig
