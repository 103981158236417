import { Typography, TypographyProps } from '@mui/material'
import { formatString } from '../util/formatString'

const ZText = (props: TypographyProps) => {
  const { children, ...rest } = props

  return (
    <Typography whiteSpace="pre-wrap" {...rest}>
      {typeof children === 'string' ? formatString(children) : children}
    </Typography>
  )
}

export default ZText
