export const capitalizeFirst = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

export const capitalizeWords = (words: string) => {
  return words
    .split(' ')
    .map(w => capitalizeFirst(w))
    .join(' ')
}
