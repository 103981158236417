import { CircularProgress } from '@mui/material'
import Button, { ButtonProps } from '@mui/material/Button'

interface ZButtonProps extends ButtonProps {
  loading?: boolean
}

const ZButton = (props: ZButtonProps) => {
  const { loading, disabled, ...rest } = props

  return (
    <Button
      variant="contained"
      size="large"
      color="primary"
      disabled={disabled || loading}
      {...rest}
      fullWidth
    >
      {props?.loading ? <CircularProgress size={26} /> : props.children}
    </Button>
  )
}

export default ZButton
