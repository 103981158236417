import { Link, Typography } from '@mui/material'
import { useState } from 'react'
import ZButton from '../components/ZButton'
import ZIcon from '../components/ZIcon'
import { CategorisationCategoryType } from '../enums'
import ZBottomContainer from '../components/ZBottomContainer'
import SkipAppropriatenessAssessment from './SkipAppropriatenessAssessment'
import ZScreenContainer from '../components/ZScreenContainer'
import Header from '../components/Header'
import {
  PreAppropriatenessAssessmentReferredIcon,
  PreAppropriatenessAssessmentIcon,
} from '../assets/icons'

const PreAppropriatenessAssessment = ({
  onDecision,
  category,
  knowledgeHubUrl,
}: {
  onDecision: (proceed: boolean) => void
  category: CategorisationCategoryType
  knowledgeHubUrl: string
}) => {
  const isReferred =
    category === CategorisationCategoryType.HighNetWorthInvestor ||
    category === CategorisationCategoryType.CertifiedSophisticatedInvestor

  const [skipAssessment, setSkipAssessment] = useState<boolean>()

  if (skipAssessment) {
    return <SkipAppropriatenessAssessment onDecision={onDecision} />
  }

  return (
    <ZScreenContainer>
      <Header
        title={
          isReferred
            ? 'Self Categorisation Submission'
            : 'Appropriateness Assessment'
        }
      />

      <ZIcon
        Icon={
          isReferred
            ? PreAppropriatenessAssessmentReferredIcon
            : PreAppropriatenessAssessmentIcon
        }
        title="Pre appropriateness assessment icon"
        style={{ alignSelf: 'center' }}
      />

      {isReferred ? (
        <Typography>
          Thank you for completing the self categorisation. We will be in
          contact with you shortly to verify the details submitted.
          <br />
          <br />
          In the meantime, we’ll ask you to take an appropriateness test to make
          sure that digital assets are the right investment for you and what
          your rights are for any funds held in your Zumo account.
          <br />
          <br />
          If you don’t pass on your first two attempts, you can retake the test
          after a 24-hour period if you choose to do so.
          <br />
          <br />
          Before the test, you can check out these{' '}
          <Link href={knowledgeHubUrl} underline="none" target="_blank">
            12 topics
          </Link>{' '}
          to make sure you are comfortable with your crypto knowledge.
        </Typography>
      ) : (
        <Typography>
          Next, we’ll ask you to take an appropriateness test to make sure that
          digital assets are the right investment for you and that you
          understand what your rights are for any funds held in your Zumo
          account.
          <br />
          <br />
          If you don’t pass on your first two attempts, you can retake the test
          after a 24-hour period if you choose to do so.
          <br />
          <br />
          Before the test, you can check out these{' '}
          <Link href={knowledgeHubUrl} underline="none" target="_blank">
            12 topics
          </Link>{' '}
          to make sure you are comfortable with your crypto knowledge.
        </Typography>
      )}

      <ZBottomContainer>
        <ZButton onClick={() => onDecision(true)}>Start the assessment</ZButton>

        <ZButton variant="text" onClick={() => setSkipAssessment(true)}>
          I&apos;ll do this later
        </ZButton>
      </ZBottomContainer>
    </ZScreenContainer>
  )
}

export default PreAppropriatenessAssessment
