import { type DependencyList, useEffect } from 'react'

type AsyncEffect = () => Promise<void>

const useAsyncEffect = (
  asyncEffect: AsyncEffect,
  dependencies?: DependencyList,
): void => {
  useEffect(() => {
    asyncEffect()
    return () => {}
  }, dependencies)
}

export default useAsyncEffect
