import { Container, ContainerProps } from '@mui/material'

const ZScreenContainer = (props: ContainerProps) => {
  const { children, style, ...rest } = props

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        ...style,
      }}
      {...rest}
    >
      {children}
    </Container>
  )
}

export default ZScreenContainer
