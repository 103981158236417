import { Link, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { AppropriatenessAssessment } from '../types'
import { AppropriatenessAssessmentStatus, SessionStorageKeys } from '../enums'
import ZIcon from '../components/ZIcon'
import ZBottomContainer from '../components/ZBottomContainer'
import ZButton from '../components/ZButton'
import SkipAppropriatenessAssessment from './SkipAppropriatenessAssessment'
import ZScreenContainer from '../components/ZScreenContainer'
import Header from '../components/Header'
import {
  AppropriatenessAssessmentPassIcon,
  AppropriatenessAssessmentFailIcon,
} from '../assets/icons'
import { ConfigUtils } from '../util/configUtils'

const AppropriatenessAssessmentCompleted = ({
  assessment,
  onDecision,
  knowledgeHubUrl,
}: {
  assessment: AppropriatenessAssessment
  onDecision: (retry: boolean) => void
  knowledgeHubUrl: string
}) => {
  const [skipRetry, setSkipRetry] = useState<boolean>()

  const deepLinkRedirect = ConfigUtils.getDeepLinkRedirectForOrganisation(
    sessionStorage.getItem(SessionStorageKeys.OrganisationId)!,
  )

  const authCode = sessionStorage.getItem(SessionStorageKeys.AuthCode)

  const renderCompleteScreen = useCallback(() => {
    switch (assessment.status) {
      case AppropriatenessAssessmentStatus.CoolingOff:
      case AppropriatenessAssessmentStatus.Passed:
        return (
          <>
            <Header title="Next Step" />
            <ZIcon
              Icon={AppropriatenessAssessmentPassIcon}
              title="Appropriateness assessment pass icon"
            />

            <Typography textAlign="center">
              Thank you for completing the assessment.
            </Typography>

            <Typography textAlign="center">
              You will be able to see the status of your submission in your
              profile.
            </Typography>

            {deepLinkRedirect ? (
              <>
                <Typography textAlign="center">
                  Please press the button below to return to the app
                </Typography>

                <ZBottomContainer>
                  <ZButton
                    onClick={() =>
                      window.open(`${deepLinkRedirect}/${authCode}`, '_self')
                    }
                  >
                    Return to the app
                  </ZButton>
                </ZBottomContainer>
              </>
            ) : (
              <Typography>
                You can close this page and return to the app.
              </Typography>
            )}
          </>
        )
      case AppropriatenessAssessmentStatus.Pending:
        return (
          <>
            <Header title="Oh no!" />

            <ZIcon
              Icon={AppropriatenessAssessmentFailIcon}
              title="Appropriateness assessment fail icon"
            />

            <Typography>
              Oops, some of your answers were incorrect.
              <br />
              <br />
              Before you start trading, we must make sure that digital assets
              are the right investment for you.
              <br />
              <br />
              If you wish, you can give it another try by completing a new
              assessment.
              <br />
              <br />
              Before the test, you can check out these{' '}
              <Link href={knowledgeHubUrl} underline="none" target="_blank">
                12 topics
              </Link>{' '}
              to make sure you are comfortable with your crypto knowledge.
            </Typography>

            <ZBottomContainer>
              <ZButton onClick={() => onDecision(true)}>
                Complete a new assessment
              </ZButton>

              <ZButton variant="text" onClick={() => setSkipRetry(true)}>
                I don&apos;t want to go through this process
              </ZButton>
            </ZBottomContainer>
          </>
        )
      case AppropriatenessAssessmentStatus.Failed:
        switch (assessment.attempt) {
          case 2:
            return (
              <>
                <Header title="Oh no!" />

                <ZIcon
                  Icon={AppropriatenessAssessmentFailIcon}
                  title="Appropriateness assessment fail icon"
                />

                <Typography>
                  Oops, some of your answers were incorrect.
                  <br />
                  <br />
                  You can try again in 24 hours if you choose to do so.
                  <br />
                  <br />
                  If you don’t pass on your next attempt, you will have to wait
                  4 weeks to try one last time. This will give you the time to
                  really consider if investment in crypto is for you.
                  <br />
                  <br />
                  Before you make this new attempt, why not check out these{' '}
                  <Link href={knowledgeHubUrl} underline="none" target="_blank">
                    12 topics
                  </Link>{' '}
                  to ensure you are comfortable with your crypto knowledge while
                  you wait?
                  <br />
                  <br />
                  {deepLinkRedirect
                    ? 'Please press the button below to return to the app'
                    : 'You can now close this page and return to the app'}
                </Typography>

                {deepLinkRedirect ? (
                  <ZBottomContainer>
                    <ZButton
                      onClick={() =>
                        window.open(`${deepLinkRedirect}/${authCode}`, '_self')
                      }
                    >
                      Return to the app
                    </ZButton>
                  </ZBottomContainer>
                ) : null}
              </>
            )
          // handle >=3 attempts
          case 3:
          default:
            return (
              <>
                <Header title="Oh no!" />

                <ZIcon
                  Icon={AppropriatenessAssessmentFailIcon}
                  title="Appropriateness assessment fail icon"
                />

                <Typography>
                  Oops, some of your answers were incorrect.
                  <br />
                  <br />
                  You can try again in 4 weeks time. This will give you the time
                  to really consider if investment in crypto is for you and to
                  seek financial advice.
                  <br />
                  <br />
                  Please note that this will be your last chance to pass the
                  assessment.
                  <br />
                  <br />
                  Before you make this new attempt, why not check out these{' '}
                  <Link href={knowledgeHubUrl} underline="none" target="_blank">
                    12 topics
                  </Link>{' '}
                  to ensure you are comfortable with your crypto knowledge while
                  you wait?
                  <br />
                  <br />
                  {deepLinkRedirect
                    ? 'Please press the button below to return to the app'
                    : 'You can now close this page and return to the app'}
                </Typography>

                {deepLinkRedirect ? (
                  <ZBottomContainer>
                    <ZButton
                      onClick={() =>
                        window.open(`${deepLinkRedirect}/${authCode}`, '_self')
                      }
                    >
                      Return to the app
                    </ZButton>
                  </ZBottomContainer>
                ) : null}
              </>
            )
        }

      default:
        return null
    }
  }, [assessment])

  if (skipRetry) {
    return <SkipAppropriatenessAssessment onDecision={onDecision} />
  }

  return <ZScreenContainer>{renderCompleteScreen()}</ZScreenContainer>
}

export default AppropriatenessAssessmentCompleted
