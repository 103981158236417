import ZIcon from '../components/ZIcon'
import { ErrorIcon } from '../assets/icons'
import ZText from '../components/ZText'
import ZScreenContainer from '../components/ZScreenContainer'

const ErrorScreen = () => {
  return (
    <ZScreenContainer>
      <ZIcon Icon={ErrorIcon} />

      <ZText variant="h6" textAlign="center">
        Something&apos;s not quite right here.
        <br />
        Please make sure the URL is valid.
      </ZText>
    </ZScreenContainer>
  )
}

export default ErrorScreen
